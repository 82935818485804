<template>
  <!--    Vehicle Form-->
  <validation-observer
    ref="vehicleForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${updateVehicleMode?'Update':'Create'} Vehicle`"
      :show="vehicleModalOpened"
      :show-overlay="deletingVehicle"
      size="md"
      @onClose="closeVehicleModal()"
    >
      <error-display :error="error" />
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedVehicleId"
            :options="vehicles"
            label-name="label"
            value-name="id"
            placeholder="Vehicles"
            @input="populateVehiclesForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteVehicle()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @onClick="resetVehicleForm()"
          />
        </div>
      </b-row>
      <lenon-input
        v-model="vehicle.label"
        name="label"
        placeholder="Enter vehicle label"
        rules="required"
        label="Label"
      />
      <lenon-input
        v-model="vehicle.number"
        name="number"
        placeholder="Enter Vehicle Number"
        rules="required"
        label="Vehicle Number"
      />
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeVehicleModal()"
          />
          <lenon-button
            :label="updateVehicleMode?'Update':'Create'"
            :disabled="invalid"
            :loading="vehicleLoading"
            loading-text="Loading..."
            @onClick="updateVehicleMode?updateVehicle():createVehicle()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>

import {
  BRow, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import confirm from '@/lenon/mixins/confirm'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import {
  CREATE_EXPENSE_M,
  DELETE_EXPENSE_M,
  UPDATE_EXPENSE_M,
} from '@/graphql/mutations'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import LenonDatePicker from '@/lenon/components/LenonDatePicker.vue'
import logData from '@/libs/log'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import LenonTextArea from '@/lenon/components/LenonTextArea.vue'

export default {
  name: 'VehicleSetup',
  components: {
    LenonTextArea,
    ErrorDisplay,
    LenonDatePicker,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast, confirm],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      error: {},
      deletingVehicle: false,
      vehicleModalOpened: false,
      updateVehicleMode: false,
      vehicleLoading: false,
      selectedVehicleId: null,
      vehicle: {
        id: null,
        label: null,
        number: null,
      },
    }
  },
  computed: {
    vehicles() {
      return this.$store.getters['vehicle/vehicles']
    },
    selectedVehicle() {
      return this
        .vehicles.find(g => +g.id === +this.selectedVehicleId)
    },
  },
  watch: {
    modalOpened(opened) {
      this.vehicleModalOpened = opened
    },
    selectedVehicleId(id) {
      if (!id) {
        this.resetVehicleForm()
      }
    },
    id(id) {
      if (id) {
        this.selectedVehicleId = id
      }
    },
  },
  methods: {
    resetVehicleForm() {
      this.updateVehicleMode = false
      this.vehicle = {
        id: null,
        label: null,
        number: null,
      }
      this.selectedVehicleId = null
      this.$refs.vehicleForm.reset()
    },
    populateVehiclesForm(ef) {
      if (this.selectedVehicle) {
        this.updateVehicleMode = true
        this.vehicle = {
          ...this.selectedVehicle,
        }
        // eslint-disable-next-line no-underscore-dangle
        delete this.vehicle.__typename
      } else {
        this.resetVehicleForm()
      }
    },
    closeVehicleModal() {
      this.vehicleModalOpened = false
      this.$emit('modalClosed')
    },
    updateVehicle() {
      this.error = {}
      if (!this.vehicle.id) {
        return
      }
      this.vehicleLoading = true
      this.$http.put(`transportation/vehicles/${this.vehicle.id}`, this.vehicle).then(res => {
        this.vehicleLoading = false
        this.showSuccess('Updated vehicle successfully')
        this.$store.commit('vehicle/updateVehicle', res.data)
      })
        .catch(err => {
          this.error = err
          logData(err)
          this.showError('Failed to update vehicle')
          this.vehicleLoading = false
        })
    },
    createVehicle() {
      this.error = {}
      this.vehicleLoading = true
      this.$http.post('transportation/vehicles', this.vehicle).then(res => {
        this.showSuccess('Created vehicle successfully')
        this.vehicleLoading = false
        this.$store.commit('vehicle/addVehicle', res.data)
        this.resetVehicleForm()
      })
        .catch(err => {
          this.error = err
          logData(err)
          this.showError('Failed to create vehicle')
          this.vehicleLoading = false
        })
    },
    deleteVehicle() {
      if (!this.selectedVehicleId) {
        this.showInfo('Please select a vehicle ')
        return
      }
      this.deletingVehicle = true
      this.$http.delete(`transportation/vehicles/${this.selectedVehicleId}`).then(() => {
        this.deletingVehicle = false
        this.showSuccess('Deleted vehicle successfully')
        this.$store.commit('vehicle/removeVehicle', this.selectedVehicleId)
        this.selectedVehicleId = null
        this.resetVehicleForm()
      })
        .catch(err => {
          logData(err)
          this.showError('Failed to delete vehicle')
          this.deletingVehicle = false
        })
    },
  },
}
</script>
