<template>
  <div class="vehicles">
    <lenon-list-page
      :title="`Payments By ${school?school.name:''}`"
      :columns="columns"
      :rows="payments"
      :table-loading="tableLoading"
      :show-create="false"
      :show-refresh="false"
      :show-search="true"
      :show-details="true"
      search-placeholder="Search"
    >
      <template #table-header>
          <div class="col-12 mb-1">
            <lenon-button @onClick="$router.back()" label="Go Back"/>
          </div>
      </template>
      <template #amount="{row}">
        {{ $store.getters['auth/currency'] }}{{ row.item.amount|currency }}
      </template>
      <template #your_share="{row}">
        {{ $store.getters['auth/currency'] }}{{ row.item.your_share|currency }}
      </template>
    </lenon-list-page>

    <vehicles-setup
      :modal-opened="vehiclesModalOpened"
      @modalClosed="closeModal('vehicles')"
    />
  </div>
</template>

<script>
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import VehiclesSetup from '@/views/transportation/settings/vehicles/VehicleSetup.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem'
import { VBToggle, BCollapse, BBadge } from 'bootstrap-vue'
import LenonSelect from '@/lenon/components/LenonSelect'

export default {
  name: 'Vehicles',
  components: {
    LenonSelect,
    LenonDropdownItem,
    LenonDropdown,
    LenonListPage,
    VehiclesSetup,
    LenonButton,
    BBadge,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [showToast],
  data() {
    return {
      vehiclesModalOpened: false,
      tableLoading: false,
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'payment_date',
          label: 'Payment Date',
        },
        {
          key: 'amount',
          label: 'Amount',
        },
        {
          key: 'your_share',
          label: 'Your Share',
        },
      ],
    }
  },
  computed: {
    payments() {
      return this.$store.getters['affiliate/payments']
    },
    school() {
      return this.$store.getters['affiliate/school']
    },
  },
  watch: {
  },
  methods: {

  },
}
</script>
